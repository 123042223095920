$primary-color: #18b3ab;
$secondary-color: #e7f8f7;
$tertiary-color: #18b3ab;

$small-size: 10px;
$normal-size: 14px;
$large-size: 18px;

.button {
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 700;
  text-align: center;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, color 0.3s;
  background: none;

  &.small {
    font-size: $small-size;
    padding: 12px 8px;
  }

  &.normal {
    font-size: $normal-size;
    padding: 16px 14px;
  }

  &.large {
    font-size: $large-size;
    padding: 20px 14px;
  }

  &.primary {
    background-color: $primary-color;
    color: white;

    &:hover {
      background-color: darken($primary-color, 10%);
    }
  }

  &.secondary {
    background-color: $secondary-color;
    color: $primary-color;

    &:hover {
      background-color: darken($secondary-color, 10%);
    }
  }

  &.tertiary {
    color: $tertiary-color;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #ccc;
    color: #777;
    pointer-events: none;
  }
}
