@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

$appFont: "Open Sans", sans-serif;
$colorTeal: #18b3ab;
$colorGrey: #344054;

body {
  background-color: #f5f8ff;
  color: $colorGrey;
  font-family: $appFont;
  .btn {
    border-radius: 5px;
  }

  .sidebar .nav > .nav-item a p,
  .sidebar[data-background-color="white"] .nav > .nav-item a p {
    color: #344054;
  }

  h1.page-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 130%;
    color: #1a1a1a;
    margin-top: 24px;
  }

  .btn-teal {
    background-color: $colorTeal;
    border-color: $colorTeal;
    color: #f5f8ff;
  }

  .btn-edit {
    border: 2px solid #f79009;
    color: #f79009;
    background-color: #feefc7;
  }

  .pill-danger {
    background-color: #fecdca;
    border-radius: 4px;
    border: 1px solid #d92d20;
    color: #d92d20;
    padding: 5px;
    font-size: 12px;
    font-weight: 600;
  }

  .pill-success {
    background-color: #d1fadf;
    border-radius: 4px;
    border: 1px solid #12b76a;
    color: #12b76a;
    padding: 5px;
    font-size: 12px;
    font-weight: 600;
  }

  .btn-teal-outline {
    border: 2px solid $colorTeal;
    background-color: #ffff;
    color: $colorTeal;
  }

  .wizard-controller {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    .step-wrapper {
      width: 150px;
      height: 150px;
      // background-color: lightgray;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .step-indicator {
        width: 65px;
        height: 65px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #98a2b3;
        border-radius: 50%;
        color: white;
      }
      .active {
        border: 3px solid $colorTeal;
        color: $colorTeal;
        background-color: white;
        transition: 0.5s;
      }
      .complete {
        background-color: $colorTeal;
        color: white;
        transition: 0.5s;
      }
      .text-active {
        color: $colorTeal;
      }
      p {
        color: $colorGrey;
        font-weight: bold;
        text-transform: uppercase;
        margin-top: 10px;
      }
    }
    hr {
      border: 2px solid #98a2b3;
      flex: 1;
      margin-top: 50px;
    }
    .complete {
      border: 2px solid $colorTeal;
      background-color: $colorTeal;
    }
  }

  hr {
    border: 1px solid #ecedf0;
    width: 100%;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .alert-warning {
    border-radius: 5px;
    border: none;
    color: #f79009 !important;
  }

  // custom style authentication page
  .login {
    .wrapper-login {
      .container {
        box-shadow: none !important;
        border-radius: 20px !important;
        width: 560px !important;
        .title {
          margin-top: 30px;
          color: $colorGrey;
          font-size: 32px;
          font-weight: 800;
        }
        .app-name {
          font-weight: 800;
          font-size: 14px;
          text-transform: uppercase;
          color: $colorTeal;
          margin-top: -20px;
        }
        .forgot-password {
          p {
            font-size: 13px;
            font-weight: 600;
            span {
              color: $colorTeal !important;
            }
          }
        }
        .login-form {
          padding: 20px !important;
          .login-instruction {
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            color: $colorGrey;
          }
          .form-group {
            .form-control {
              border: 1px solid #ecedf0;
              border-radius: 6px;
            }
            .btn-sign-in {
              background-color: $colorTeal;
              color: #ffff;
              border-radius: 5px;
            }
          }
          .form-control:focus {
            border: 1px solid #18b3ab;
          }
          .text-link {
            color: $colorTeal;
            font-weight: 700;
          }
        }
      }
    }
  }
  // custom style main page
  .wrapper {
    .dashboard-wrapper {
      background-color: #ffff;
      border-radius: 10px;
      box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.08),
        0px 1px 4px 0px rgba(0, 0, 0, 0.08);
      .title {
        font-size: 24px;
        font-weight: 400;
        margin-bottom: 10px;
      }
      margin-bottom: 300px;
    }

    .nav-pills {
      .nav-link {
        border: none;
      }
    }

    .nav-pills.nav-success {
      .nav-link.active {
        background-color: #e7f8f7;
        color: $colorTeal !important;
        border: none;
        border-bottom: 3px solid $colorTeal;
        font-weight: bold;
      }
    }

    .form-group {
      .form-control {
        border: 1px solid #ecedf0;
        border-radius: 5px;
      }
      .form-control:focus {
        border: 1px solid $colorTeal;
      }
    }
  }

  .sidebar {
    .nav-collapse li a .sub-item:before,
    &[data-background-color="white"] .nav-collapse li a .sub-item:before {
      background: unset;
      left: 0px;
    }
  }

  .icon-menu {
    width: 20px;
    margin-right: -20px;
  }

  .sidebar.sidebar-style-2 .nav .nav-item a {
    padding: 8px 10px;
    border-radius: 5px;
    margin-top: 8px;
  }
}

.bg-secondary-gradient {
  background: linear-gradient(
    200.23deg,
    #1eb8af 4.21%,
    #5dd29a 100%
  ) !important;
}

.btn-teal {
  background-color: $colorTeal;
}

.btn-teal-online {
  border: 2px solid $colorTeal;
}

.copyright {
  // margin-top: 150px;
  p {
    font-size: 13px;
    text-align: center;
  }
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: $colorTeal;
  border-color: $colorTeal;
}

.form-grouping {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .warning {
    color: #484848;
    font-size: 14px;
    font-weight: 400;
    line-height: 130%;
    span {
      color: #d91919;
    }
  }

  .section-title {
    font-size: 16px;
    font-weight: 700;
    line-height: 130%;
    color: #1a1a1a;
    margin-bottom: 24px;
  }

  .button_action {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
  }

  .mmp_group_id_wrapper {
    padding: 12px 16px;
    border-radius: 10px;
    border: 1px solid #d0f4f2;
    background: rgba(231, 248, 247, 0.45);
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .data_item {
      flex: 1;
      position: relative;
      justify-content: start;
      padding: 0 16px;
      p {
        margin: 0;
        color: #313131;
        line-height: 130%;
        &.label {
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 8px;
        }
        &.value {
          font-size: 14px;
          font-weight: 600;
        }
      }

      &::before {
        content: "";
        position: absolute;
        height: 100%;
        width: 1px;
        background-color: #d0f4f2;
        left: 0;
        top: 0;
      }

      &:first-child::before {
        display: none;
      }
    }
  }
}

.leaflet-control-attribution {
  display: none;
}