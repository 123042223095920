.dashboard-wrapper {
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    h1 {
      font-size: 24px;
      font-weight: 700;
      line-height: 130%;
      color: #1a1a1a;
    }
    .search-wrapper {
      width: 450px;
    }
  }
  .items-report {
    margin-top: 40px;

    .empty-data-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        margin-top: 80px;
        margin-bottom: 32px;
      }
      h3 {
        font-size: 22px;
        font-weight: 700;
        line-height: 130%;
        color: #1a1a1a;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 150%;
        color: #484848;
      }
    }

    .grid-item {
      padding: 16px 14px;
      border: 1px solid #ecedf0;
      border-radius: 8px;
      background-color: #fbfafa;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 120px;
      cursor: pointer;

      &:hover {
        background-color: #e7f8f7;
        border: 1px solid #18b3ab;
        transition: all 0.3s ease-in-out;
      }

      .icon-wrapper {
        width: 36px;
        height: 36px;
        border-radius: 100%;
        background-color: #fff;
        filter: drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.08))
          drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.08));
        display: flex;
        margin-bottom: 16px;
        img {
          margin: auto;
        }
      }

      h3 {
        font-size: 14px;
        font-weight: 600;
        line-height: 130%;
        color: #1a1a1a;
      }
    }
  }
}
