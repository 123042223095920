.pageWrapper {
  min-height: 100vh;
  background-color: #f5f8ff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    margin-bottom: 48px;
  }
  .loginCardWrapper {
    width: 100%;
    max-width: 660px;
    background-color: #fff;
    border-radius: 20px;
    padding: 70px;

    @media only screen and (max-width: 768px) {
      padding: 40px;
    }

    h1 {
      font-size: 32px;
      font-weight: 800;
      color: #344054;
      line-height: 40px;
      text-align: center;
      margin-bottom: 6px;
      // For Mobile Phone
      @media only screen and (max-width: 768px) {
        font-size: 22px;
      }
    }
    p {
      text-align: center;
      font-size: 14px;
      font-weight: 800;
      color: #18b3ab;
      line-height: 16px;
    }
    form {
      margin-top: 57px;
      .formContainer {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        color: #344054;
        @media only screen and (max-width: 768px) {
          font-size: 12px;
        }
      }
    }
  }
}
