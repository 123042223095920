.step-indicator-container {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  .step-indicator-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .step-indicator-wrap {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
      .step-indicator-icon {
        width: 26px;
        height: 26px;
        border-radius: 26px;
        background-color: #18b3ab;
        border: 1px solid #d0f4f2;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
      }
      .step-indicator-title {
        p {
          margin: 0 !important;
          font-size: 16px;
          font-weight: 600;
          color: #1a1a1a;
        }
      }
    }
    .step-indicator-bar {
      height: 8px;
      border-radius: 4px;
      background-color: #18b3ab;
    }
  }
}
