.alert-wrapper {
  /* background-color: orange; */
  text-align: center;
  padding: 0.75rem;
  border-radius: 0.75rem;
  margin-bottom: 0.55rem;
  color: black;
  font-weight: bold;
}
.alert-wrapper p {
  margin: 0;
  padding: 0;
}
