.filter {
  width: 100%;
  border: 1px solid #ecedf0;
  border-radius: 10px;

  .filter-header {
    padding: 16px 20px;
    background-color: #f4f4f4;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    p {
      margin: 0;
      padding: 0;
      font-size: 16px;
      font-weight: 600;
      line-height: 130%;
      color: #1d2939;
    }
    button {
      width: 30px;
      height: 30px;
      border-radius: 30px;
      border: none;
      background-color: #ffff;
      box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.08),
        0px 1px 4px 0px rgba(0, 0, 0, 0.08);
      color: #18b3ab;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
  .filter-body {
    background-color: #fff;
    padding: 16px 20px;
    .filter-item {
      display: flex;
      gap: 50px;
      flex-direction: row;
      align-items: flex-start;
      padding: 16px 0;
      .filter-label {
        width: 230px;
        p {
          margin: 0;
          padding: 0;
          font-size: 16px;
          color: #313131;
          font-weight: 600;
          line-height: 130%;
        }
      }
      .filter-field {
        width: 100%;
        .advanced-filter {
          margin-top: 12px;
        }
        .filter-condition {
          display: flex;
          width: 100%;
          flex-direction: row;
          gap: 32px;
        }
      }
      &:not(:last-child) {
        border-bottom: 1px solid #ecedf0;
      }
    }
    .filter-action {
      margin-top: 24px;
    }
    .warning {
      margin-top: 24px;
      padding: 16px 12px;
      background-color: #fffaf2;
      border: 1px solid #fcc064;
      border-radius: 10px;
      p {
        margin: 0;
        font-size: 13px;
        color: #1a1a1a;
        span.text-title {
          color: #a76603;
        }
      }
    }
  }
}
