.formContainer {
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    padding: 0;
  }

  input[type="checkbox"] {
    margin-right: 8px;
    cursor: pointer;
  }

  input[type="checkbox"]:checked{
    background-color: #18b3ab !important;
    border-color: #18b3ab !important;
  }

  input[type="checkbox"] + span {
    font-size: 14px;
  }

  input[type="checkbox"]:disabled {
    cursor: not-allowed;
  }

  &.error {
    color: #d91919;
  }

  .invalidInput {
    font-size: 12px;
    font-weight: 400;
    line-height: 100%;
    color: #d91919;
  }
}
