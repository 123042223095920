.card-filter {
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  padding: 16px;
  border-radius: 10px;
  background-color: #fbfafa;
  border: 1px solid #ecedf0;
  .filter-item {
    flex: 1;
  }
}

.informasi-wilayah {
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 16px;
  border-radius: 10px;
  background-color: #fbfafa;
  border: 1px solid #ecedf0;
  .list-item {
    display: flex;
    flex-direction: row;
    margin: 10px 0;
    p {
      margin: 0;
    }
    .item-label {
      width: 200px;
      font-size: 14px;
      font-weight: 400;
      line-height: 130%;
      color: #313131;
    }
    .item-value {
      flex: 1;
      font-size: 14px;
      font-weight: 600;
      line-height: 130%;
      color: #313131;
    }
  }
}
