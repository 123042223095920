$colorNeutral: #1a1a1a;

.dashboard-wrapper {
  padding: 36px !important;
  border-radius: 10px !important;

  h1.page-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 130%;
    color: $colorNeutral;
    margin-bottom: 12px;
  }
  p.page-subtitle {
    font-size: 14px;
    font-weight: 400;
    color: $colorNeutral;
    line-height: 130%;
  }

  // .filter-container {
  //   margin-top: 24px;
  //   border: 1px solid #ecedf0;
  //   border-radius: 8px;
  //   .filter-header {
  //     background-color: #f4f4f4;
  //     padding: 16px 20px;
  //     display: flex;
  //     align-items: center;
  //     justify-content: space-between;
  //     flex-direction: row;
  //     h2 {
  //       font-size: 16px;
  //       font-weight: 600;
  //       color: #1d2939;
  //       letter-spacing: 0.48px;
  //       line-height: 130%;
  //       margin: 0;
  //       padding: 0;
  //     }
  //     button {
  //       width: 30px;
  //       height: 30px;
  //       border-radius: 37px;
  //       box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.08),
  //         0px 1px 4px 0px rgba(0, 0, 0, 0.08);
  //       background-color: #fff;
  //       display: flex;
  //       justify-content: center;
  //       align-items: center;
  //       color: #18b3ab;
  //       border: none;
  //     }
  //   }
  //   .filter-body {
  //     padding: 20px;
  //     .filter-content {
  //       width: 75%;
  //       display: flex;
  //       align-items: flex-end;
  //       border-bottom: 1px solid #ecedf0;
  //       padding: 10px 0;
  //       .filter-label {
  //         width: 210px;
  //         p {
  //           font-size: 16px;
  //           font-weight: 600;
  //           color: #313131;
  //         }
  //       }
  //       .filter-element {
  //         width: 515px;
  //       }
  //     }
  //   }
  // }
}
