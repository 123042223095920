.required-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
  span {
    color: #d91919;
  }
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .form_action {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
}
