.formContainer {
  display: flex;
  gap: 12px;
  flex-direction: column;

  label {
    font-size: 14px;
    font-weight: 600;
    color: #313131;
  }

  input {
    border: 1px solid #ecedf0;
    font-size: 14px;
    font-weight: 400;
    line-height: 100%;
    padding: 16px 12px;
    height: 44px;
    border-radius: 6px;
    transition: border-color 0.3s ease-in-out;
    // background-color: #FBFAFA;

    &:focus {
      border: 1px solid #18b3ab;
      outline: none;
    }

    &:disabled {
      background-color: #f4f4f4;
      cursor: not-allowed;
    }

    &::placeholder {
      color: #bababa;
    }

    &.error {
      border: 1px solid #d91919;
      &:focus {
        border: 1px solid #d91919;
      }
    }
    &.shadowEffect {
      box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.08),
        0px 1px 4px 0px rgba(0, 0, 0, 0.08);
    }
  }

  textarea {
    border: 1px solid #ecedf0;
    font-size: 14px;
    font-weight: 400;
    line-height: 100%;
    padding: 16px 12px;
    border-radius: 6px;
    transition: border-color 0.3s ease-in-out;
    // background-color: #FBFAFA;

    &:focus {
      border: 1px solid #18b3ab;
      outline: none;
    }

    &:disabled {
      background-color: #f4f4f4;
      cursor: not-allowed;
    }
    &:read-only {
      background-color: #f4f4f4;
      cursor: not-allowed;
    }

    &::placeholder {
      color: #bababa;
    }

    &.error {
      border: 1px solid #d91919;
      &:focus {
        border: 1px solid #d91919;
      }
    }
    &.shadowEffect {
      box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.08),
        0px 1px 4px 0px rgba(0, 0, 0, 0.08);
    }
  }

  .invalidInput {
    font-size: 12px;
    font-weight: 400;
    line-height: 100%;
    color: #d91919;
  }
}
