.dashboard-wrapper {
  h1 {
    font-size: 24px;
    font-weight: 700;
    line-height: 130%;
    color: #1a1a1a;
  }

  .filter-container-2 {
    margin-top: 24px;
    margin-bottom: 24px;
    width: 100%;
    border: 1px solid #ecedf0;
    border-radius: 10px;
    .filter-header-2 {
      display: flex;
      background: #f4f4f4;
      min-width: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 16px 20px;
      p {
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: 600;
        line-height: 130%;
        letter-spacing: 0.48px;
        color: #1d2939;
      }
      button {
        padding: 0 0;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        border: none;
        box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.08),
          0px 1px 4px 0px rgba(0, 0, 0, 0.08);
        background-color: #fff;
        color: #18b3ab;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .filter-body-2 {
      padding: 20px;
      .filter-item-wrapper {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 1rem;
        margin-top: 1rem;
        .filter-label {
          width: 20%;
          p {
            margin-top: 0;
            margin-bottom: 0;
            font-size: 16px;
            font-weight: 600;
            line-height: 130%;
            color: #313131;
          }
        }
        .filter-item {
          flex-grow: 1;
          .subfilter-item {
            margin-top: 12px;
            display: flex;
            flex-direction: row;
            gap: 40px;

            .InputDate_formContainer__-7QPf {
              width: 100% !important;
            }
            .InputSelect_formContainer__e0tZY {
              width: 100% !important;
            }
          }
        }
      }
      .filter-action {
        display: flex;
        flex-direction: row;
        gap: 10px;
      }
    }
  }

  .table-container {
    width: 100%;
    border: 1px solid #d0f4f2;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 24px;
    .data-title {
      padding: 12px 20px;
      background: #e7f8f7;
      h2 {
        margin: 0;
        padding: 0;
        font-size: 18px;
        font-weight: 700;
        line-height: 130%;
        color: #0c928b;
      }
    }
    .data-main {
      padding: 20px;
      background-color: #fff;

      /* Customize scrollbar track */
      .table-responsive::-webkit-scrollbar {
        // width: 6px !important; /* Set the width of the scrollbar */
        height: 6px !important;
        background-color: #ecedf0 !important;
        padding: 3px;
        border-radius: 5px;
      }

      /* Customize scrollbar thumb */
      .table-responsive::-webkit-scrollbar-thumb {
        background-color: #d0d1d3; /* Color of the thumb */
        border-radius: 5px; /* Rounded corners */
      }

      table {
        background-color: #fff !important;
        margin-top: 0;
        margin-bottom: 0;
      }

      .pagination-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 1rem;
        .left-side {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;
          p {
            margin: 0;
            padding: 0;
            font-size: 12px;
            font-weight: 400;
            line-height: 130%;
            color: #1a1a1a;
          }
        }
        .right-side {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;
          button {
            width: 32px !important;
            height: 32px !important;
            border-radius: 8px !important;
            color: #18b3ab;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
}
