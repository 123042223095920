.modal {
  .modal-content {
    padding: 28px;
    border: 0;
    border-radius: 10px;
  }
  h5.modal-title {
    font-size: 18px;
    font-weight: 700;
    color: #1a1a1a;
  }
}
