.legend-wrapper {
  margin-top: 0.15rem;

  h2 {
    font-size: 14px;
    font-weight: bold;
  }

  .legends {
    .legend-item {
      .legend-color {
        display: inline-block;
        width: 1rem;
        height: 1rem;
        border-radius: 0.25rem;
        margin-right: 0.5rem;
        border: 1px solid #ddd;
      }
    }
  }

  .static-tooltip {
    background-color: white;
    color: black;
    border: 1px solid #ccc;
    padding: 2px;
    font-size: 12px;
    opacity: 0.8;
  }
}
