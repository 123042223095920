button {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: center;
  &:hover {
    opacity: 0.8;
  }
}

.btnEdit {
  border: 0;
  background-color: transparent;
  padding: 6px 8px;
  background-color: #e7f8f7;
  border: 1px solid #d0f4f2;
  color: #0c928b;
  border-radius: 7px;
  font-size: 12px;
  font-weight: 600;
}

.btnDelete {
  border: 0;
  background-color: transparent;
  padding: 6px 8px;
  background-color: #fff2f2;
  border: 1px solid #ffd8d8;
  color: #bc0b0b;
  border-radius: 7px;
  font-size: 12px;
  font-weight: 600;
}

.btnKinerja {
  border: 0;
  background-color: transparent;
  padding: 6px 8px;
  background-color: #f2faff;
  border: 1px solid #dcf0fc;
  color: #066ba5;
  border-radius: 7px;
  font-size: 12px;
  font-weight: 600;
}

.btnDetail {
  border: 0;
  background-color: transparent;
  padding: 6px 8px;
  background-color: rgba(255, 240, 217, 0.50);
  border: 1px solid #FEE5BF;
  color: #A76603;
  border-radius: 7px;
  font-size: 12px;
  font-weight: 600;
}
