.form-wrapper {
  h1.page-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 130%;
    color: #1a1a1a;
    margin-top: 24px;
  }
  .data-information {
    background-color: #fbfafa;
    width: 100%;
    padding: 16px;
    border-radius: 10px;
    border: 1px solid #ecedf0;
    .data-item {
      display: flex;
      flex-direction: row;
        padding: 10px 0;
      width: 550px;

      @media screen and (max-width: 600px) {
        flex-direction: column;
      }

      .label {
        font-size: 14px;
        font-weight: 400;
        color: #313131;
        width: 200px;
      }
      .value {
        flex-grow: 1;
        font-size: 14px;
        font-weight: 600;
        color: #313131;
      }
    }
  }
  .form-container {
    margin-top: 24px;
    .action-button {
      margin-top: 24px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      gap: 24px;
    }

    .list-data {
      padding: 16px;
      border: 1px solid #ecedf0;
      border-radius: 10px;
      margin-bottom: 16px;

      .data-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: 600px) {
          flex-direction: column;
          align-items: start;
        }

        > div.list-wrapper {
          flex: 1;
          display: flex;
          flex-direction: row;
          // align-items: start;
          padding: 0 16px; /* Adjust padding as needed */
          position: relative;
          gap: 10px;

          .circle-outer {
            background-color: #e7f8f7;
            width: 23px;
            height: 23px;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .circle-inner {
              width: 18px;
              height: 18px;
              border-radius: 100%;
              background-color: #18b3ab;
              display: flex;
              align-items: center;
              justify-content: center;
              p {
                font-size: 13px;
                font-weight: 700;
                color: #fff;
                // margin: auto;
              }
            }
          }

          &::before {
            content: "";
            position: absolute;
            height: 100%;
            width: 1px;
            background-color: #ecedf0;
            left: 0;
            top: 0;
          }

          &:first-child::before {
            display: none;
          }
        }

        p {
          margin: 0;
          padding: 0;

          &.text-label {
            font-size: 14px;
            font-weight: 400;
            line-height: 130%;
            color: #313131;
            margin-bottom: 6px;
          }

          &.text-value {
            font-size: 14px;
            font-weight: 600;
            line-height: 130%;
            color: #313131;
          }
        }

        button {
          background-color: transparent;
          border: none;
          outline: none;
          cursor: pointer;
          font-size: 14px;
          color: #313131;
          font-weight: 400;
        }
      }

      .data-container ~ .data-container {
        border-top: 1px solid #ecedf0;
        margin-top: 16px;
        padding-top: 16px;
      }
    }
  }
}
