.custom-page-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .page-title {
    margin: 0 !important;
    font-size: 24px;
    font-weight: 700;
    line-height: 130%;
    color: #1a1a1a;
  }
}

.filter-data-container {
  margin: 24px 0;
  width: 100%;
  background-color: #fbfafa;
  padding: 16px;
  border: 1px solid #ecedf0;
  border-radius: 10px;
  .cadre-information {
    display: flex;
    flex-direction: row;
    padding: 8px 0;
    .info-label {
      width: 20%;
      font-size: 14px;
      font-weight: 400;
      line-height: 130%;
      color: #313131;
    }
    .info-value {
      font-size: 14px;
      font-weight: 600;
      line-height: 130%;
      color: #313131;
    }
  }
  .filter-item {
    display: flex;
    flex-direction: row;
    .filter-label {
      width: 20%;
      font-size: 14px;
      font-weight: 400;
      line-height: 130%;
      color: #313131;
    }
    .filter-control {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }
  }
}
