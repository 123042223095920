.detail-data-wrapper {
  margin-top: 2rem;
  border: 1px solid #ecedf0;
  //   padding: 0.5rem;
  border-radius: 10px;
  overflow: hidden;
  .detail-data-item {
    padding: 0.8rem 1rem;
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid #ecedf0;
    &:not(:last-child) {
      border-bottom: 1px solid #ecedf0;
    }

    &:nth-child(2n + 2) {
      /* Memilih setiap baris kedua */
      background-color: #fbfafa; /* Ganti dengan warna latar belakang yang diinginkan */
    }

    .label {
      min-width: 24rem;
    }

    .separator {
      min-width: 1rem;
    }

    .value {
      font-weight: 600;
    }
  }
}
