.filter {
  width: 100%;
  border: 1px solid #ecedf0;
  border-radius: 10px;

  .filter-header {
    padding: 16px 20px;
    background-color: #f4f4f4;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    p {
      margin: 0;
      padding: 0;
      font-size: 16px;
      font-weight: 600px;
      line-height: 130%;
      color: #1d2939;
    }
    button {
      width: 30px;
      height: 30px;
      border-radius: 30px;
      border: none;
      background-color: #fff;
      box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.08),
        0px 1px 4px 0px rgba(0, 0, 0, 0.08);
      color: #18b3ab;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
  .filter-body {
    background-color: #fff;
    padding: 16px 20px;
    .filter-item {
      display: flex;
      gap: 50px;
      flex-direction: row;
      align-items: flex-start;
      padding: 16px 0;
      .filter-label {
        width: 230px;
        p {
          margin: 0;
          padding: 0;
          font-size: 16px;
          color: #313131;
          font-weight: 600;
          line-height: 130%;
        }
      }
      .filter-field {
        width: 100%;
      }
      &:not(:last-child) {
        border-bottom: 1px solid #ecedf0;
      }
    }
    .filter-action {
      margin-top: 24px;
    }
  }
}

.warning-message {
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding: 16px 12px;
  background-color: #fffaf2;
  border: 1px solid #fcc064;
  border-radius: 8px;
  .icon {
    color: #fcc064;
    font-size: 18px;
  }
  .message {
    font-size: 13px;
    color: #1d2939;
  }
}

.subtitle-section {
  margin: 0;
  font-size: 14px;
  font-weight: 700;
}

.form-grouping {
  .form_action {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 24px;
  }
}
