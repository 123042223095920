table {
  margin-top: 24px;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 10px;
  border-radius: 10px;
  padding: 10px;
  tr {
    border: 1px solid #ecedf0;
    border-radius: 10px;
  }
  th {
    font-weight: 600;
    color: #1a1a1a;
    text-align: left;
    padding: 16px 12px;
    border-top: 1px solid #ecedf0;
    &.sticky-column {
      position: sticky;
      left: 0;
    }
  }

  td {
    font-weight: 400;
    line-height: 130%;
    color: #484848;
    border: 1px solid #ecedf0;
    text-align: left;
    padding: 16px 12px;
  }
  th {
    background-color: #fff;
    border: 1px solid #ecedf0;
  }
  tr:nth-child(even) {
    background-color: #fbfafa;
  }

  tr:hover {
    background-color: #fbfafa;
  }
}

.pagination-container {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .pagination-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    select {
      width: 48px;
      height: 32px;
      background-color: #fff;
      box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.08),
        0px 1px 4px 0px rgba(0, 0, 0, 0.08);
      border: 1px solid #ecedf0;
      border-radius: 4px;
    }
    p {
      margin: 0 !important;
    }
  }
  .pagination-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    .info-page {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      .active-page {
        width: 32px;
        height: 32px;
        box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.08),
          0px 1px 4px 0px rgba(0, 0, 0, 0.08);
        border: 1px solid #ecedf0;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        p {
          margin: 0 !important;
        }
      }
      p {
        margin: 0 !important;
      }
    }
    button {
      width: 32px;
      height: 32px;
      border: 1px solid #ecedf0;
      background-color: transparent;
      border-radius: 8px;
      color: #18b3ab;
      font-size: 24px;
      &:disabled {
        cursor: not-allowed;
      }
    }
  }
}
