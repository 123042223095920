.page-container {
  padding: 36px;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.08),
    0px 1px 4px 0px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  background-color: #fff;

  h1.page-title {
    margin-top: 16px;
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 700;
    line-height: 130%;
    color: #1a1a1a;
  }

  .data-information {
    padding: 16px;
    background-color: #fbfafa;
    border: 1px solid #ecedf0;
    border-radius: 10px;
  }

  .data-container {
    display: flex;
    padding: 10px 0;
    .label {
      font-size: 14px;
      font-weight: 400;
      line-height: 130%;
      color: #313131;
      width: 145px;
    }
    .value {
      font-size: 14px;
      font-weight: 600;
      line-height: 130%;
      color: #313131;
    }
  }

  .action-button{
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
    gap: 24px;
  }

}
