.filter-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #fbfafa;
  border-radius: 0.8rem;
  border: 1px solid #ecedf0;

  .filter-content {
    display: flex;
    gap: 4rem;
    align-items: center;
    p {
      margin: 0;
      padding: 0;
    }
  }
}
