.breadcrumb {
  padding: 0 !important;
  background-color: transparent;
  .breadcrumb-item {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 130%;
    color: #1a1a1a !important;
    a {
      color: #1a1a1a;
      text-decoration: none;
    }
    &.active {
      font-weight: 600;
    }
  }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    margin-top: 5px;
    color: #6c757d;
    content: url("data:image/svg+xml,%3Csvg width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.25 11L8.75 7.5L5.25 4' stroke='%231A1A1A' stroke-width='1.4' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
}
