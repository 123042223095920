.sidebar.sidebar-style-2 {
  box-shadow: none;
  .nav.nav-primary {
    > .nav-item.active {
      > a {
        background-color: #d1fadf !important;
        box-shadow: none;
        border-left: 6px solid #18b3ab;
      }
      svg {
        color: #18b3ab;
      }
    }
  }
}

.sidebar.sidebar-style-2 .nav.nav-primary > .nav-item.active > a p,
.sidebar.sidebar-style-2 .nav.nav-primary > .nav-item.active > a i,
.sidebar.sidebar-style-2 .nav.nav-primary > .nav-item.active > a .caret,
.sidebar.sidebar-style-2 .nav.nav-primary > .nav-item.active > a span {
  color: #18b3ab !important;
}

@media only screen and (max-width: 600px) {
  .sidebar,
  .sidebar[data-background-color="white"] {
    width: 250px;
  }
}
