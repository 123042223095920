.main-header {
  background-color: #fff;
  box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.12);

  .logo-header {
    width: 300px;
  }

  .icon-menu {
    color: #1a1a1a;
  }
}

@media screen and (max-width: 600px) {
  .main-header {
    .logo-header {
      width: 0;
    }
  }
}

@media screen and (min-width: 991px) {
}
