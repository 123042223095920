.page-title {
  margin: 0 !important;
  font-size: 24px;
  font-weight: 700;
  line-height: 130%;
  color: #1a1a1a;
}

.navigation-module {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 24px;
  button {
    padding: 12px 7px;
    font-size: 14px;
    font-weight: 700;
    line-height: 130%;
    color: #bababa;
    background-color: transparent;
    border: none;
    cursor: pointer;
    &.active {
      color: #18b3ab;
      background-color: #e7f8f7;
      border-bottom: 2px solid #18b3ab;
    }
  }
}

.form-grouping {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .warning {
    color: #484848;
    font-size: 14px;
    font-weight: 400;
    line-height: 130%;
    span {
      color: #d91919;
    }
  }

  .section-title {
    font-size: 16px;
    font-weight: 700;
    line-height: 130%;
    color: #1a1a1a;
    margin-bottom: 24px;
  }

  .button_action {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
  }

  .mmp_group_id_wrapper {
    padding: 12px 16px;
    border-radius: 10px;
    border: 1px solid #d0f4f2;
    background: rgba(231, 248, 247, 0.45);
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .data_item {
      flex: 1;
      position: relative;
      justify-content: start;
      padding: 0 16px;
      p {
        margin: 0;
        color: #313131;
        line-height: 130%;
        &.label {
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 8px;
        }
        &.value {
          font-size: 14px;
          font-weight: 600;
        }
      }

      &::before {
        content: "";
        position: absolute;
        height: 100%;
        width: 1px;
        background-color: #d0f4f2;
        left: 0;
        top: 0;
      }

      &:first-child::before {
        display: none;
      }
    }
  }
}
