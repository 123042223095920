.action-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  .action-button {
    max-width: 300px;
  }
  .action-search {
    width: 450px;
  }
}

.button-actions {
  display: flex;
  flex-direction: row;
  gap: 8px;
  .button-edit {
    background-color: transparent;
    padding: 0;
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 12px;
    font-weight: 600;
    color: #6938ef;
  }

  .button-delete {
    background-color: transparent;
    padding: 0;
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 12px;
    font-weight: 600;
    color: #d92d20;
  }
}

.pagination-wrapper {
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  gap: 6px;
  button {
    width: 36px;
    height: 36px;
    background-color: #fff;
    border: 1px solid #ecedf0;
    border-radius: 8px;
    color: #18b3ab;
    cursor: pointer;
  }
}

.filter-year {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
}

.table-responsive::-webkit-scrollbar {
  // width: 6px !important; /* Set the width of the scrollbar */
  height: 6px !important;
  background-color: #ecedf0 !important;
  padding: 3px;
  border-radius: 5px;
}

/* Customize scrollbar thumb */
.table-responsive::-webkit-scrollbar-thumb {
  background-color: #d0d1d3; /* Color of the thumb */
  border-radius: 5px; /* Rounded corners */
}
